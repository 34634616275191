<template>
    <nav class="navBar h-20 w-screen flex place-content-evenly absolute bg-white top-0 border-b-2 border-blue-300">
        
        <router-link @click.native="scrollToTop" class="flex pl-4 items-center" to="/">
            <img alt="logo" class="logo h-16" src="../assets/logo.png">
            <div class="name pl-3 flex flex-col text-xl font-bold">
                <span>PACKYMS</span>
                <span>TECHNOLOGY</span>
            </div>
        </router-link>

        <div class=" md:flex text-xl items-center hidden">
            <span>Services:</span>
            <div class="font-semibold pl-2">
                <span @mouseover="showdropmenu(true,'software')" @mouseleave="showdropmenu(false,'software')" class=" navBtn">Software
                  <div v-if="showSoftwareMenu" class=" dropdownNavMenu">
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/WebDevelopment">Web Development</router-link>
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/MobileDesktopAppDevelopment">Mobile & Desktop App Development</router-link>
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/CustomSoftwareDevelopment">Custom Development</router-link>
                  </div>
                </span> |
                <span @mouseover="showdropmenu(true,'security')" @mouseleave="showdropmenu(false,'security')" class=" navBtn">Security
                  <div v-if="showSecurityMenu" class=" dropdownNavMenu">
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/PenetrationTesting">Penetration testing</router-link>
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/WebSecurity">Web security</router-link>
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/ComputerServerSecurity">Computer & server security</router-link>

                  </div>
                 
                </span> |
                <span @mouseover="showdropmenu(true,'research')" @mouseleave="showdropmenu(false,'research')" class=" navBtn">Research
                  <div v-if="showResearchMenu" class=" dropdownNavMenu">
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/SoftwareResearch">Software Research and development</router-link>
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/HardwareResearch">Hardware Research and development</router-link>
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/SecurityResearch">Systems Security Research</router-link>

                  </div>
                  
                </span> |
                <span @mouseover="showdropmenu(true,'hardware')" @mouseleave="showdropmenu(false,'hardware')" class=" navBtn">Hardware
                  <div v-if="showHardwareMenu" class=" dropdownNavMenu">
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/GeneralElectronicRepair">General Electronic Hardware Repair</router-link>
                    
                  </div>
                </span> |
                <span  @mouseover="showdropmenu(true,'consulting')" @mouseleave="showdropmenu(false,'consulting')" class=" navBtn">Consulting
                  <div v-if="showConsultingMenu" class=" dropdownNavMenu">
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/StrategicBusinessesConsulting">Strategic Business Consulting</router-link>
                    <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/GeneralConsulting">General Consulting</router-link>

                  </div>
                </span>
                
            </div>
            
        </div>

        <button @click="showbar(true)"  class="sidemenubtn w-14 h-14 self-center md:hidden hover:scale-105 active:scale-95 ml-auto mr-4" id="sidemenubtn">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-auto h-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>

        </button>


        <div v-if="showdropbar" class="sidemenu absolute p-4 w-screen overflow-y-scroll bg-blue-400"  >
            <div class="h-20 absolute w-screen">
                <div @click="showbar(false)"  class=" w-14 h-14 self-center md:hidden hover:scale-105 active:scale-95 ml-auto mr-10">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-auto h-auto">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>
            </div>
        <div class="mobileDropDownMenu md:ml-10 mt-20">
            <div class="dropdownNavSectionCont">
              <h1 class="dropdownNavTitle">Software</h1>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/WebDevelopment">Web Development</router-link>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/MobileDesktopAppDevelopment">Mobile & Desktop App Development</router-link>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/CustomSoftwareDevelopment">Custom Development</router-link>
            </div>
            <div class="dropdownNavSectionCont">
              <h1 class="dropdownNavTitle">Security</h1>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/PenetrationTesting">Penetration testing</router-link>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/WebSecurity">Web security</router-link>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/ComputerServerSecurity">Computer & server security</router-link>
            
            </div>
            <div class="dropdownNavSectionCont">
              <h1 class="dropdownNavTitle">Research</h1>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/SoftwareResearch">Software Research and development</router-link>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/HardwareResearch">Hardware Research and development</router-link>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/SecurityResearch">Systems Security Research</router-link>
            
            </div>
            <div class="dropdownNavSectionCont">
              <h1 class="dropdownNavTitle">Hardware</h1>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/GeneralElectronicRepair">General Electronic Hardware Repair</router-link>
            
            </div>
            <div class="dropdownNavSectionCont">
              <h1 class="dropdownNavTitle">Consulting</h1>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/StrategicBusinessesConsulting">Strategic Business Consulting</router-link>
              <router-link @click.native="showbar(false)" class="dropdownNavBrn" to="/GeneralConsulting">General Consulting</router-link>
              <!-- <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700" to="/">Geneal electronic hardware sales</router-link> -->
            
            </div>
            </div>
            <img alt="logo" class="logo h-20 mx-auto" src="../assets/logo.png">
        </div>
        
    </nav>
</template>

<script>
    
    export default {
      data () {
        return {
         showdropbar: false,
         showSoftwareMenu: false,
         showSecurityMenu: false,
         showResearchMenu: false,
         showHardwareMenu: false,
         showConsultingMenu: false,

        };
      },
      methods: {
        showdropmenu(show,menu){
          if(show){
            if(menu == 'software'){
              this.showSoftwareMenu = true
            }
            else if(menu == 'security'){
              this.showSecurityMenu = true
            }
            else if(menu == 'research'){
              this.showResearchMenu = true
            }
            else if(menu == 'hardware'){
              this.showHardwareMenu = true
            }
            else if(menu == 'consulting'){
              this.showConsultingMenu = true
            }
            
          }
          else{
            if(menu == 'software'){
              this.showSoftwareMenu = false
            }
            else if(menu == 'security'){
              this.showSecurityMenu = false
            }
            else if(menu == 'research'){
              this.showResearchMenu = false
            }
            else if(menu == 'hardware'){
              this.showHardwareMenu = false
            }
            else if(menu == 'consulting'){
              this.showConsultingMenu = false
            }
          }
        },
        showbar(show){
          if (show){
            this.showdropbar = true;
          }
          else{
            this.showdropbar = false;
            this.scrollToTop();
          }
        },
        scrollToTop() {
          // window.scrollTo(0,0);
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        }
      },
 
    };
</script>
<style scoped>

  .navBtn{
    @apply relative cursor-pointer;
  }
  .dropdownNavMenu{
    @apply absolute bg-white w-52 p-4 space-y-2 right-0  border-blue-300 border ;
  }
  .dropdownNavSectionCont{
    @apply md:ml-10 space-y-4 pb-10;
  }
  .dropdownNavTitle{
    @apply text-4xl font-bold text-gray-700 border-b-4 border-blue-300 pb-2;
  }
  .dropdownNavBrn{
    @apply flex pl-4 items-center text-xl font-semibold text-gray-700 hover:scale-105 active:scale-95;
  }
</style>