import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/Checkout',
    name: 'Checkout',  
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckoutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },

  // Consulting
  {
    path: '/GeneralConsulting',
    name: 'GeneralConsulting',
    component: () => import(/* webpackChunkName: "about" */ '../views/Consulting/GeneralConsultingView.vue')
  },
  {
    path: '/StrategicBusinessesConsulting',
    name: 'StrategicBusinessesConsulting',
    component: () => import(/* webpackChunkName: "about" */ '../views/Consulting/StrategicBusinessesConsultingView.vue')
  },

  // Research
  {
    path: '/HardwareResearch',
    name: 'HardwareResearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/Research/HardwareResearchView.vue')
  },
  {
    path: '/SecurityResearch',
    name: 'SecurityResearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/Research/SecurityResearchView.vue')
  },
  {
    path: '/SoftwareResearch',
    name: 'SoftwareResearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/Research/SoftwareResearchView.vue')
  },

  //Security
  {
    path: '/ComputerServerSecurity',
    name: 'ComputerServerSecurity',
    component: () => import(/* webpackChunkName: "about" */ '../views/Security/ComputerServerSecurityView.vue')
  },
  {
    path: '/PenetrationTesting',
    name: 'PenetrationTesting',
    component: () => import(/* webpackChunkName: "about" */ '../views/Security/PenetrationTestingView.vue')
  },
  {
    path: '/WebSecurity',
    name: 'WebSecurity',
    component: () => import(/* webpackChunkName: "about" */ '../views/Security/WebSecurityView.vue')
  },

  //Software
  {
    path: '/CustomSoftwareDevelopment',
    name: 'CustomSoftwareDevelopment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Software/CustomSoftwareDevelopmentView.vue')
  },
  {
    path: '/MobileDesktopAppDevelopment',
    name: 'MobileDesktopAppDevelopment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Software/MobileDesktopAppDevelopmentView.vue')
  },
  
  {
    path: '/WebDevelopment',
    name: 'WebDevelopment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Software/WebDevelopmentView.vue')
  },

  // Hardware
  {
    path: '/GeneralElectronicRepair',
    name: 'GeneralElectronicRepair',
    component: () => import(/* webpackChunkName: "about" */ '../views/Hardware/GeneralElectronicRepairView.vue')
  },

  // Hardware
  {
    path: '/labs/resumebuilder',
    name: 'resumebuilder',
    component: () => import(/* webpackChunkName: "about" */ '../views/labs/ResumeBuilder/MyResumeView.vue')
  },
  {
    path: '/labs/tests',
    name: 'Tests',
    component: () => import(/* webpackChunkName: "about" */ '../views/labs/Tests/SiteTestsView.vue')
  },

]



const router = new VueRouter({
  mode:'history',
  routes,
  
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
})

export default router
