<template>
  <div class="home w-full px-6 md:px-20 mt-4">
    <div class="moto flex flex-col content-center justify-center text-center mt-20">
        <h1 class="font-semibold text-lg text-gray-500">PACKYMS Technology</h1>
        <p class="pt-3 font-bold text-3xl text-gray-700"> Help you get ahead in the digital space. </p>
        <p class="pt-3 font-bold text-3xl text-gray-700" > Research is the key to a better tomorrow. </p>
    </div>

    <div class="intro flex flex-col content-center justify-center text-center mt-20">
      <h1 class="font-semibold text-lg text-gray-500">PACKYMS Technology - A research & development, Software Development, cyber security, & Consulting firm </h1>
      <div class="flex mt-5 sp">       
        <p class="pt-3 font-bold text-3xl text-gray-700"> Our main goal is to develop technology that will help make everyone's life easier. We develop websites, software for servers, desktop and mobile applications, develop AI applications, develop data management systems for education institutions/ government institutions/ small, big or personal businesses </p>
      </div>
      <div class=" bg-blue-400 w-full h-72 mt-10">
        
      </div>
      
    </div>

    <div class="flex mt-20 flex-col md:flex-row ">
      <div class="text-4xl font-bold text-gray-700 md:w-1/3">
        <p>What we do:</p>
        <div class=" mt-10 md:h-1/2 flex justify-center ">
          <img alt="logo" class=" object-cover h-36 w-full md:h-fit md:w-2/3 " src="../assets/image3.jpg">
        </div>
      </div>
      <div class=" md:ml-10 mt-4">
        <div class="md:ml-10 space-y-4 pb-10">
          <h1 class="text-4xl font-bold text-gray-700 border-b-4 border-blue-300 pb-2">Software</h1>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/WebDevelopment">Web Development</router-link>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/MobileDesktopAppDevelopment">Mobile & Desktop App Development</router-link>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/CustomSoftwareDevelopment">Custom Development</router-link>
        </div>
        <div class="md:ml-10 space-y-4 pb-10">
          <h1 class="text-4xl font-bold text-gray-700 border-b-4 border-blue-300 pb-2">Security</h1>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/PenetrationTesting">Penetration testing</router-link>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/WebSecurity">Web security</router-link>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/ComputerServerSecurity">Computer & server security</router-link>
          
        </div>
        <div class="md:ml-10 space-y-4 pb-10">
          <h1 class="text-4xl font-bold text-gray-700 border-b-4 border-blue-300 pb-2">Research</h1>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/SoftwareResearch">Software Research and development</router-link>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/HardwareResearch">Hardware Research and development</router-link>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/SecurityResearch">Systems Security Research</router-link>
          
        </div>
        <div class="md:ml-10 space-y-4 pb-10">
          <h1 class="text-4xl font-bold text-gray-700 border-b-4 border-blue-300 pb-2">Hardware</h1>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/GeneralElectronicRepair">General Electronic Hardware Repair</router-link>
          
        </div>
        <div class="md:ml-10 space-y-4 pb-10">
          <h1 class="text-4xl font-bold text-gray-700 border-b-4 border-blue-300 pb-2">Consulting</h1>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/StrategicBusinessesConsulting">Strategic businesses consulting</router-link>
          <router-link class="flex pl-4 items-center text-2xl font-semibold text-gray-700 hover:scale-105 active:scale-95" to="/GeneralConsulting">General consulting</router-link>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
   
  }
}
</script>
