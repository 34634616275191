<template>
    <!-- <div class="footer flex flex-col p-5 bg-slate-300 bottom-0 border-t-2 border-blue-300 w-screen mt-auto"> -->
    <div class="footer">
        <!-- <div class="logo_cont flex pl-4 h-full w-full items-center justify-center"> -->
        <div class="logo_cont ">
            <img alt="logo" class="h-16" src="../assets/logo.png">
            <div class="name pl-3 flex flex-col text-xl font-bold">
                <span>PACKYMS</span>
                <span>TECHNOLOGY</span>
            </div>
        </div>
        <div class=" pt-5 justify-center flex w-full"> 
            <router-link @click.native="scrollToTop" to="/contact" class="btn p-2 px-6 bg-white border-2 border-gray-500 w-fit hover:scale-105 hover:bg-slate-100 hover:cursor-pointer active:scale-95 active:bg-slate-300"> Contact Us</router-link>
        </div>
        <div class="mt-10 flex flex-col space-y-3 md:space-y-0 md:flex-row justify-evenly w-full">
            <div class="Links flex flex-col">
                <h1 class="text-2xl font-bold">Links</h1>
                <router-link class="text-xl text-white font-bold hover:text-slate-100 hover:scale-105 active:scale-95" to="/" >Linkedin</router-link>
            </div>
            <div class="Links flex flex-col">
                <h1 class="text-2xl font-bold">Careers</h1>
                <p to="/" class="text-xl text-white " >We are not yet hiring</p>
            </div>
            <div class="Links flex flex-col">
                <h1 class="text-2xl font-bold">Office</h1>
                <div class="text-xl text-white">
                    <p>We are located in zambia ndola,</p> 
                    <p>our work is mostly done remote and</p>  
                    <p>sometime onsite based on the project </p>
                    <p>requirments</p>
                </div>
                
            </div>
        </div>

        <div class="copyright mt-10 flex-row text-center w-full">
            <div class="d font-medium">
                <p>© 2023 PACKYMS </p>
                <p>We can move as one.</p>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src


export default {
 
  methods: { 
  scrollToTop() {
    // window.scrollTo(0,0);
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
}
  
}
</script>

<style>

 .footer{
    /* display: flex; */
    /* flex-direction: column; */
    padding: 1.25rem;
    background-color: rgb(203, 213, 225);
    
    /* bottom: 0px; */
    border-top-width: 2px;
    border-color: rgb(147, 197, 253);
    /* margin-top: auto; */
    width: 100vw;
    /* height: 100px; */
 }

 .logo_cont{
    /* position: relative; */
    display:flex;
    padding-left: 1rem;
    height: 100%;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
 }

</style>