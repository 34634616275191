const sidemenu = document.querySelector('#sidemenu')
const sidemenubtn = document.querySelector('#sidemenubtn')

if (sidemenubtn) {
	sidemenubtn.addEventListener('click', () => { 
        console.log("dsknskdfnsdkfn")
		if (sidemenu.classList.contains('hidden')) {
			sidemenu.classList.remove('hidden')
		} else {
			sidemenu.classList.add('hidden')
		}
	})
}