<template>
  <div id="app">
    <navbar/>   
    <!-- <panel></panel> -->
    <!-- <viewport/> -->
    <!-- <transition name="fade" mode="out-in"> -->
      <router-view/>
    <!-- </transition> -->
    <footerbar/>
  </div>
</template>

<script>
  // import ViewPort from "@/components/ViewPort.vue";
  // import ControlPanel from "@/components/ControlPanel.vue";
  import Navbar from "@/components/NavBar.vue";
  import Footerbar from "@/components/FooterBar.vue";
  export default {
    components: {
      // viewport: ViewPort,
      // panel: ControlPanel,
      navbar: Navbar,
      footerbar: Footerbar,
    }
  };
</script>

<style>
html,
body {
  width: 100vw;
  height: 100%;
  display: flex;
  overflow: auto;
}
body {
  margin: 0px;
}
canvas {
  position: relative;
}
#app {
  height: 100%;
  width: 100vw;
  /* display: flex; */
  flex-direction: column;
  /* overflow: auto; */
}
</style>